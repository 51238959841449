import React from 'react';
import { graphql } from 'gatsby';
import 'moment/locale/nl-be';
import moment from 'moment';

import styled from 'styled-components';
import Layout from '../layout/defaultLayout';
import Container from '../components/layout/Container';
// import accessTime from '../images/accessTime.svg';

const StyledAnnouncement = styled.div`
  img {
    width: 600px;
    max-width: 100%;
    height: auto;
    padding: 20px;
  }
  video {
    display: block;
    margin-bottom: 40px;
    width: 500px;
    height: auto;
  }
  margin-bottom: 20px;
`;

export default function Announcement({ data }) {
  moment.locale('nl-be');

  const {
    titel,
    createdAt,
    childContentfulMededelingTekstTextNode,
    youtubeId,
    // hoofdafbeelding,
    // contentful_id
  } = data.contentfulMededeling;

  return (
    <Layout>
      <Container>
        <StyledAnnouncement>
          <h1>{titel}</h1>
          {childContentfulMededelingTekstTextNode && (
            <div
              dangerouslySetInnerHTML={{
                __html: childContentfulMededelingTekstTextNode.childMarkdownRemark.html,
              }}
            />
          )}
          {/* {video && video.file && (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video controls width="320" height="240">
              <source src={video.file.url} type={video.file.contentType} alt={video.title} />
            </video>
          )} */}
          <p style={{ display: 'flex', alignItems: 'center' }}>
            <svg
              className="jss1 time"
              focusable="false"
              viewBox="0 0 24 24"
              aria-hidden="true"
              role="presentation"
              style={{ marginRight: 6 }}
            >
              <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path>
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z"></path>
            </svg>
            {moment(createdAt).format('dd D/MM HHumm')}
          </p>
          {youtubeId && (
            <iframe
              style={{ maxWidth: '100%' }}
              id="ytplayer"
              title="video"
              type="text/html"
              width="640"
              height="360"
              src={`https://www.youtube.com/embed/${youtubeId}?autoplay=1&origin=http://example.com`}
              frameBorder="0"
            ></iframe>
          )}
        </StyledAnnouncement>
      </Container>
    </Layout>
  );
}

export const query = graphql`
  query($contentfulId: String!) {
    contentfulMededeling(contentful_id: { eq: $contentfulId }) {
      titel
      childContentfulMededelingTekstTextNode {
        childMarkdownRemark {
          html
        }
      }
      youtubeId
      createdAt
    }
  }
`;
